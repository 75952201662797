@import "../../../variables";

/**
 * Ukoliko neodgovara veličina potrebno je naštelovati onda:
 *  Svuda gde ima (ima primer u media query):
 *      width, height, margin, transform
 *  Opciono gde ima:
 *      scale
 */




@mixin switch-transition {
    transition: transform 0.2s ease-in-out;
}

@mixin switch-background($type, $flipped) {
    background: linear-gradient(110deg, $type);
    background-size: 200% 100%;
    background-position: 45% 0;

    @if $flipped =="off" {
        box-shadow: inset -5px 10px 10px -5px rgba(0, 0, 0, 0.5);
    }

    @else if $flipped =="on" {
        box-shadow: inset 5px 10px 10px -5px rgba(0, 0, 0, 0.5);
    }
}

$fh-gradient: $svetlo_plava 8%, $bela 12%, $bela 18%, $svetlo_plava 22%;
$bc-gradient: $tamno_ljubicasta 8%, $bela 12%, $bela 18%, $tamno_ljubicasta 22%;
$gradient-animacija-fh: cubic-bezier(.66, 1.19, .62, .42);
$gradient-animacija-bc: cubic-bezier(.68, .96, .51, .16);
$dužina-animacije: 2s;

.switch-container {
    text-align: start;
    /*OBAVEZNO OVO JER JE JOKSA STAVIO TEXT-ALIGN:CENTER*/
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &>* {
        @include switch-transition;
    }

    .switch-dugme {
        @include switch-background($fh-gradient, "off");
        /*width: 152px;
        height: 79px;*/
        width: 115px;
        height: 60px;
        border-radius: 100px;
        cursor: pointer;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            background-color: $bela;
            width: 49.2px;
            height: 49.2px;
            border-radius: 100%;
            margin: 5.3px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);

            @include switch-transition;
        }
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"]:checked+.switch-dugme {
        @include switch-background($bc-gradient, "on");
    }

    input[type="checkbox"]:checked+.switch-dugme::before {
        transform: translateX(55.2px);
        box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.6);
    }


}

.switch-pulse-fh {
    animation-name: gradientMoveFH;
    animation-duration: $dužina-animacije;
    animation-iteration-count: forwards;
    animation-composition: accumulate;
}


.switch-pulse-bc {
    animation-name: gradientMoveBC;
    animation-duration: $dužina-animacije;
    animation-iteration-count: forwards;
    animation-composition: accumulate;
}

/*
input[type="checkbox"]:checked+.switch-pulse {
    animation-name: gradientMoveBC;
}
*/
@keyframes gradientMoveFH {

    0% {
        animation-timing-function: ease-out;
        background-position: 45% 0;
        transform: scale(1);
    }

    10% {
        animation-timing-function: $gradient-animacija-fh;
        background-position: 45% 0;
        transform: scale(1.5);
    }

    90% {
        background-position: -135% 0;
        transform: scale(1.5);
    }

    100% {
        animation-timing-function: ease-out;
        background-position: -135% 0;
        transform: scale(1);
    }
}

@keyframes gradientMoveBC {

    0% {
        animation-timing-function: ease-out;
        background-position: 45% 0;
        transform: scale(1);
    }

    10% {
        animation-timing-function: $gradient-animacija-bc;
        background-position: 45% 0;
        transform: scale(1.5);
    }

    90% {
        background-position: -135% 0;
        transform: scale(1.5);
    }

    100% {
        animation-timing-function: ease-out;
        background-position: -135% 0;
        transform: scale(1);
    }
}

@media (max-width: 1920px) {

    .switch-container {
        .switch-dugme {
            width: 87px;
            height: 45px;
            box-shadow: inset -2px 5px 5px -2px rgba(0, 0, 0, 0.5);

            &::before {
                width: 37px;
                height: 37px;
                margin: 4px 4.5px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
            }
        }

        input[type="checkbox"]:checked+.switch-dugme {
            box-shadow: inset 2px 5px 5px -2px rgba(0, 0, 0, 0.5);
        }

        input[type="checkbox"]:checked+.switch-dugme::before {
            transform: translateX(41px);
            box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
        }
    }
}

//promenio jer jbg
@media (max-width: 400px) {

    .switch-container {

        .switch-dugme {
            width: 67.7px;
            height: 35px;
            box-shadow: inset -2px 5px 5px -2px rgba(0, 0, 0, 0.5);

            &::before {
                width: 29px;
                height: 29px;
                margin: 3px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
            }
        }

        input[type="checkbox"]:checked+.switch-dugme {
            box-shadow: inset 2px 5px 5px -2px rgba(0, 0, 0, 0.5);
        }

        input[type="checkbox"]:checked+.switch-dugme::before {
            transform: translateX(32.7px);
            box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
        }
    }
}