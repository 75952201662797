@import '/src/variables';
@media (min-width: 850px) {
  .Iskustva {
    // @include backround_fh;
    text-align: center;
    font-family: $font_body;
    // height: 1379px;
    width: 100%;
    position: relative;
    z-index: 1;
    
    width: calc(100% - 400px);

    @media (max-width: 1300px) {
        width: 100%;
    }
    

    .kutija{
      position: relative;
      width: fit-content;
    }

    .content {
      @include flex-center;
      position: relative;
    }
    
    




    .naslov1 {
      //  position: absolute;
      //  left: 450px;
      //  top: 35px;
      font-family: Orbitron;
      color: $bela;
      font-size: 60px;
      font-weight: 600;
    }

    .naslov2 {
      //  position: absolute;
      //  left: 450px;
      //  top: 960px;
      font-family: Orbitron;
      color: $bela;
      font-size: 38px;
      font-weight: 500;
      padding-top: 20px;
    }

    .naslov3{
      font-family: Orbitron;
      color: $bela;
      font-size: 20px;
      font-weight: 500;
      padding-top: 10px;
    }

    .okvir {
      // position: absolute;
      // left: 430px;
      // top:208px;
      width: 338px;
      height: 585px;
      z-index: 10;
      margin-top: 20px;
    }

    .slika {
      // position: absolute;
      // left: 430px;
      // top:208px;
      position: absolute;
      // bottom: 657px;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      top: 20px;
      left: 0;
      padding-bottom: 8px;
      z-index: -1;
      mask-image: url('./imgs/border_rect.svg');


    }



    .leva {
      //position: absolute;
      //left: 296px * 0.9;
      //top:543px;
      margin-bottom: 190px;




    }

    button:hover {
      cursor: pointer;
    }

    .desna {
      // position: absolute;
      // top: 543px;
      // left: 890px;
      margin-bottom: 190px;



    }

    .Iskustvo {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;



    }
  }
}

@media (max-width: 849px) {
  .Iskustva {

    // @include backround_fh;
    text-align: center;
    font-family: $font_body;
    width: 100%;

    .naslov2 {
      //  position: absolute;
      //  left: 450px;
      //  top: 960px;
      font-family: Orbitron;
      color: $bela;
      font-size: 20px;
      font-weight: 500;
      padding-top: 20px;
    }
    .naslov3{
      font-family: Orbitron;
      color: $bela;
      font-size: 15px;
      font-weight: 500;
      padding-top: 10px;
    }


    .kutija{
      position: relative;
      width: fit-content;
    }

    .content {
      @include flex-center;
      position: relative;
    }

    .okvir {
      position: relative;
      z-index: 10;
    }

    .naslov1 {

      font-family: Orbitron;
      color: $bela;
      font-size: 32px;
      font-weight: 500;
      padding-bottom: 51px;
      padding-top: 10px;
    }

    //  .desna{
    //   position: relative;
    //   right: 227.68px;
    //  }

    button:hover {
      cursor: pointer;
    }

    .slika {

      position: relative;
      // bottom: 342px;
      z-index: 0;
      height: -webkit-fill-available;
      width: -webkit-fill-available;
      padding-bottom: 8px;
      mask-image: url('./imgs/border_mask_mobile.svg');
      position: absolute;
      // bottom: 657px;
      left: 0px;
    }


    .logo {
      padding-right: 242px;
    }

  }

}

.Iskustva{
  .kutija{

    @media (max-width: 990px) {

      .question-div{
        width: 100%;
      }

      
    }
  }
}