@import '../../variables';



.pocetna {
    position: relative;
    transform-origin: 0 0;
    // scale: .7;
    top: 0;
    left: 0;
    font-family: $font_header;

    .vrh {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-left: 95.2px; // 136px * 0.7

        .logo {
            width: 200.9px; // 287px * 0.7
            height: 200.9px; // 287px * 0.7
        }

        .naslov {
            width: 565.6px; // 808px * 0.7
            height: 87.5px; // 125px * 0.7
            margin-left: 14px; // 20px * 0.7
        }
    }

    .tajmidugm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 700px;
        width: 100vw;
    }

    .tajmer {
        margin-top: 20px;
        // margin-left: 150px;
        display: grid;
        justify-content: start;
        scale: 0.8;

        .vreme {
            align-items: start;
            font-size: 45px;
            font-weight: 600;
            background: linear-gradient(0deg, #24BDDE00, #24BDDE);
            background-clip: text;
            color: transparent;
            margin-left: -10px;
        }

        .portal {
            display: grid;
            align-items: center;
            justify-content: center;
            margin-top: -35px; // -50px * 0.7
            margin-left: 17.5px; // 25px * 0.7
            margin-right: -17.5px; // -25px * 0.7
            width: 100%;

            .trapez {
                grid-column: 1;
                grid-row: 1;
                margin-left: -50px;
                margin-bottom: -25px;
                animation: puls 4s infinite;
            }

            .elips1,
            .elips2 {
                grid-column: 1;
                grid-row: 2;
            }

            .elips2 {
                margin: 1.5px 27px;
            }
        }

        .opis {
            font-size: 17px;
            font-weight: 600;
            color: $svetlo_plava;
            margin: 30px 0;
        }
    }

    .dugmici {
        max-width: 700px; // 1000px * 0.7
        width: 100vw; // 100vw * 0.7
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -50px;

        .fonhak,
        .blockch {
            margin: 10px; // 10px * 0.7
            display: flex;
            width: 224px; // 320px * 0.7
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .tekst {
                font-size: 15px;
                margin: 15px; // 10px * 0.7
                font-weight: 600;
                height: 30px; // 50px * 0.7
            }

            .dugme {
                font-size: 20px;
                width: 100%; // 100% * 0.7
                background-color: white;
                border-radius: 14px; // 20px * 0.7
                padding: 10.5px; // 15px * 0.7
                text-align: center;
                font-weight: 500;
                letter-spacing: 2.5px;
            }
        }

        .fonhak {
            color: $svetlo_plava;

            .tekst {
                padding-top: 14px;
            }

            .dugme {
                box-shadow: 5px 5px $svetlo_plava;

                &:hover {
                    background-color: $svetlo_plava;
                    color: white;
                }
            }
        }

        .blockch {
            color: $svetlo_ljubicasta;

            .tekst {}

            .dugme {
                box-shadow: 5px 5px $svetlo_ljubicasta;
                &:hover {
                    background-color: $svetlo_ljubicasta;
                    color: white;
                }
            }
        }
    }
}

@media (max-width: 795px) {
    .vrh {
        align-items: center !important;
        margin-left: 0 !important;
        padding-top: 10%;
        .logo {
            width: 174px !important;
            height: 174px !important;
        }

        .naslov {
            width: 454px !important;
            height: 88px !important;
        }
    }

}

@media (max-width: 435px) {
    .fonhak {
        .tekst {
            padding-top: 0px !important;
        }
    }
}

@media (max-width: 600px) {
    .pocetna {
        scale: 1;

        .vrh {
            align-items: baseline;
            margin-left: 0;

            .logo {
                width: 30% !important;
                height: auto !important;
            }

            .naslov {
                width: 80% !important;
                height: auto !important;
                margin-left: 0;
            }
        }

        .tajmer {
            width: 100vw;
            margin-left: 0;
            margin-top: 50px;
            align-items: center;
            justify-content: center;
            scale: .7;

            .vreme {}

            .portal {

                .trapez {}

                .elips1,
                .elips2 {}

                .elips2 {}
            }

            .opis {
                font-size: 25px;
            }
        }

        .dugmici {
            margin-left: 0;

            .fonhak,
            .blockch {
                margin: 10px;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;

                .tekst {}

                .dugme {
                    font-size: 20px;
                    width: 100%;
                }
            }

            .fonhak {
                .tekst {}

                .dugme {}
            }

            .blockch {

                .dugme {}
            }
        }
    }
}


@keyframes puls {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}