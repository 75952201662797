@import "../../../../variables";

.question-div-bc {
    display: flex;
    flex-direction: column;
    width: 750px;
}

.title-arrow-bc {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    
    p {
        font-family: $font_body;
        max-width: 560px;
        text-align: left;
        color: white;
        font-size: 1.8rem;
        margin-right: 10px;
    }

    .strelicaBC {
        width: 60px;
        height: 60px;
    }
}

.title-div:hover {
    cursor: pointer;
}


.answer-holder-bc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    .answer-div-bc {
        max-width: 450px;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-image-source: url("./resources/prozorBC.svg");
        border-image-slice: 60 65 67 62;
        border-image-width: 40px 40px 40px 40px;
        border-image-outset: 0px 0px 0px 0px;
        border-image-repeat: stretch stretch;
        padding: 40px;

        // background-image: url('./resources/prozorBC.png');
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: contain;

        .answer {
            box-sizing: border-box;
            background: rgba($color: #FFFFFF, $alpha: 0.13);
            text-align: justify;
            color: white;
        }
    }
}

// @keyframes dropdown {
//     from {
//         max-height: 0;
//     }
//     to {
//         max-height: 300px;
//     }
// }

.answer-holder-bc.shown {
    max-height: 100vh;
    transition: max-height 1s ease-in-out;
}



.svg-div {
    position: relative;
    width: 100%;
    height: 34px;
}

//animiranje linije
#gradientSvgBC {
    position: absolute;
    top: 0;
    left: 0;
}

#obicanSvg {
    position: absolute;
    top: 0;
    left: 0;
}

#gradientLineBC, #gradientElipseBC {
    display: none;
}

#gradientLineBC.default {
    display: block;
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    animation: reverseDash 0.5s linear forwards;
}

#gradientLineBC.active {
    display: block;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 0.5s linear forwards;
}

#gradientElipseBC.active {
    display: block;
    opacity: 0;
    animation: fadeIn linear forwards;
    animation-delay: 474ms;
}

@keyframes arrowAnimation {
   0% {
    opacity: 0;
   }
   0% {
    opacity: 1;
   }
   100% {
    opacity: 0;
   }
   
  }
  
  #strela1BC {
    animation: arrowAnimation 1s ease-out infinite;
    animation-delay: -0.4s;
  }
  
  #strela2BC {
    animation: arrowAnimation 1s ease-out infinite;
    animation-delay: -0.2s;
  }
  
  #strela3BC {
    animation: arrowAnimation 1s ease-out infinite;
  }

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes reverseDash {
    to {
        stroke-dashoffset: 1000;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (max-width: 800px) {
    .question-div-bc {
        width: calc(100vw - 20px);
        padding: 0 10px;
    }

    .title-arrow-bc {
        p {
            font-size: 1.5rem;
        }

        .strelicaBC {
            min-width: 50px !important;
            min-height: 50px !important;
            max-width: 50px !important;
            max-height: 50px !important;
        }
    }

    .answer-holder-bc {
        width: 100%;
        transition: max-height 0.5s ease-in-out;
    
        .answer-div-bc {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            // background-image: url('./resources/border_rect_BC.svg');
            // background-repeat: no-repeat;
            // background-position: center;
            // background-size: cove;
    
            .answer {
                box-sizing: border-box;
                text-align: justify;
                color: white;
            }
        }
    }

    .answer-holder-bc.shown {
        max-height: 100vh;
        transition: max-height 0.5s ease-in-out;
    }
}

@media screen and (max-width: 430px) {
    .title-arrow-bc p {
        font-size: 1.2rem;
    }
}