@import '../../variables';

.zvezdemeni {
    position: absolute;
    //right: 100%;
    top: -500px;
    left: calc(100% + 500px);
    transition: top 0ms, left 400ms, right 300ms;
    transition-delay: 400ms, 0s, 0s;
    z-index: 15;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    transform: translate(-43%, -60%);

}

@media (max-width: 1300px) {
    .zvezdemeni {
        position: fixed;
    }

    header .nav-hamburger.active {
        filter: brightness(2) contrast(1);
    }

}

.otvoren {
    // right: 50px !important;
    top: 50%;
    left: 50%;
    transition: top 400ms, left 0ms, right 300ms;

}

@media (max-width: 600px) {
    .zvezdemeni {
        transform: translate(-48%, -60%);
    }
}

@media (max-width: 450px) {
    .zvezdemeni {
        transform: translate(-59%, -60%);
    }
}

.pozadinabl {
    transition: backdrop-filter 300ms ease-in-out;

    position: fixed;
    overflow: hidden;
    left: 0;
    top: -10px;
    width: 100vw;
    height: 110vh;
    z-index: 11;
    pointer-events: none;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0%);
    background-color: #00000000;
}

.blur {
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-color: #00000080;
}

.nemalogo {
    position: absolute;
    right: 0;
    top: 20px;

    .nav-logo-group {
        display: none !important;
    }
}