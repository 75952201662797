.szvzdj :first-child {
    .ime {
        margin-bottom: 15px;
        margin-top: -30px;
    }
}

.szvzdj :nth-child(3) {
    .ime {
        margin-bottom: 10px;
        margin-top: -10px;
    }
}

.szvzdj {
    position: absolute;
    right: 50px;
    top: 50px;
    width: 400px;
    height: 500px;
    // margin: 40px;

}

@media (max-width:600px) {
    .szvzdj {
        // margin: 100px 0 0 0;
        // right: 50px;
        width: 370px;
    }
}

@media (max-width:450px) {
    .szvzdj {
        scale: 0.8;
    }
}