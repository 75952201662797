@import '../../variables.scss';

.loading {
    @include backround_fh;
}

.fade-out> *{
    animation-name: scale-in;
    animation-duration: 10s;
}

svg{
    scale: 1.2;
}

@keyframes scale-in {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
    
}