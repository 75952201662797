@import "../../variables";

.ohakatonu {
    // max-width: 1000px;
    // max-width: 70%;
    // min-width: 600px;
    a{
        color: $svetlo_plava;
        text-decoration: none;
    }
    
    a :hover {
        color: $bela;
    }

        display: flex;
        flex-direction: column;
        align-items: center;

    width: calc(100% - 400px);

    @media (max-width: 1300px) {
        width: 100%;
        
    }

    h1 {
        margin-top: 50px;
        font-family: $font_header; //pedergej
        color: $bela;
        // font-size: 2rem;
    }

    .ohakatonu {
        // align-content: space-evenly;
        justify-content: center;
        margin-right: 50vw;
        margin-top: 5vh;
        // margin-bottom: 5vh;
        // overflow: hidden;
        // height: 100vh;
        gap: 30px;

    }

    .okvir {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        height: fit-content;
        width: 100%;
        // background: url('./Okvir.png');
        border-image-source: url('./Okvir.png');
        border-image-slice: 150 103 150 82;
        border-image-width: 48px 34px 48px 34px;
        // border-width: 48px 34px 48px 34px;
        border-image-outset: 48px 34px 48px 34px;
        // background-repeat: no-repeat;
        // background-position: center;
        // background-origin: content-box;
        background-size: 100% 100%;
        margin: 50px 34px;

    }


    p {
        font-family: $font_body;
        // padding: 30px;
        // padding: 65px 40px 0;
        color: $bela;
        padding: 10px;
        
  text-align: left;
        @media (min-width: 1300px) {
            padding: 1rem;
        }
    }

    ul {
        font-family: $font_body;
        color: $bela;

        li {
            margin-left: 1rem;
            padding: 0;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 80%;

        @media (max-width: 535px) {
            width: 100%;

            justify-content: space-between;
        }

        @media (min-width: 900px) {
            width: 50%;
            
        }
    }

    img {
        height: 50px;
        width: 50px;
    }

    .druga {
        transform: rotate(180deg);
    }

    .card-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        img{
            cursor: pointer;
        }
    }

    .prijava-dugme {
        width: 200px;
        margin-top: 20px;
    }
}