@import '../../variables';


.nagrade {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 calc(150vw/19.2);
    margin-top: 40px;

    width: calc(100% - 500px);

    @media (max-width: 1300px) {
        width: 100%;
        margin-left: 0;
    }

    .nagrade-naslov {
        align-self: flex-start;
        font-family: $font_header;
        color: $bela;
        font-weight: 600;
        font-size: 1.8rem;
        text-align: end;
        margin-top: 229px;
    }

    .nagrade-podijum-wrapper {
        margin-left: 69px;
    }

    .nagrade-podijum {
        position: relative;
        background: url('./assets/Group\ 74.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        //Visina slike
        //Odnos slike 889:584
        height: calc(400px * 889/584);
        width: 400px;
        min-width: 400px;

        .nagrate-container-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -50%);
        }

        .nagrade-prvo {
            top: 2%;
            left: 22%;
        }

        .nagrade-drugo {
            top: 25%;
            left: 50.5%;
        }

        .nagrade-trece {
            top: 56.5%;
            left: 78.5%;
        }

        .nagrade-container {
            font-family: $font_header;
            width: max-content;
            color: $bela;
            font-size: 1.8rem;


            padding: 5px 19px;

            background: linear-gradient(135deg, rgba(0, 36, 64, 0.2), rgba(0, 36, 64, 0.2));
            backdrop-filter: blur(2px);
            -webkit-backdrop-filter: blur(2px);

            animation: goredole 3s ease-in-out infinite;

            /*CRNA MAGIJA NE PIPAJ*/
            display: flow-root;
            position: relative;
            border-radius: 9px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 9px;
                border: 3px solid transparent;
                background: linear-gradient(to bottom, $svetlo_plava, $svetlo_ljubicasta) border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                mask-composite: exclude;
            }

        }
    }
}

@keyframes goredole {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-25%);
    }

    100% {
        transform: translateY(0px);
    }
}

@media (max-width: 900px) {
    .nagrade {
        flex-direction: column;
        margin-top: 20px;

        .nagrade-naslov {
            margin: 0 0 50px 0;
            align-self: inherit;
            text-align: center;
        }

        .nagrade-podijum-wrapper {
            margin: 0;
        }
    }
}

@media (max-width: 550px) {
    .nagrade {
        margin-top: 10px;

        .nagrade-naslov {
            font-size: 1.6rem;
        }

        .nagrade-podijum-wrapper {
            .nagrade-podijum {
                height: calc(300px * 889/584);
                width: 300px;
                min-width: 300px;
            }

            .nagrade-prvo {
                top: 1%;
            }

            .nagrade-drugo {
                top: 24%;
            }

            .nagrade-trece {
                top: 56%;
            }

            .nagrade-container {
                font-size: 1.2rem;
                padding: 6px 15px;
            }
        }
    }
}