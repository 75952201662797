@import '../../../variables';

.star {
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;

    img {
        width: 50px;
        height: 50px;
        margin-top: -10px;
        margin-left: -10px;
        z-index: 30;
    }

    .ime {
        font-size: 20px;
        color: whitesmoke;
        font-family: $font_header;
    }

}
