@import 'src/_variables';

html {
    // overflow: hidden;
}

footer {
    font-size: 12px;
    overflow: hidden;
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: $font_header;
    // @include backround_fh;
    color: $bela;
    padding: 2rem 7rem 1.5rem 7rem;
    margin-top: 1em;
}



#ft-parent {
    font-size: 1rem;
    height: 10em;
    margin-bottom: 0;
    padding-top: 2em;
    //  font-family: $font_body;
    @include flex-between;
    border-top: solid 1px $bela;
}

.ft-child {
    flex: 1;
}

#ft-adr-parent {
    font-size: 1rem;
    display: flex;
    gap: 1em;
    align-items: center;
    text-align: left;
}

.ft-adr-child {
    font-size: 1rem;
}

#ft-mapa {
    width: 3.8em;
    height: 3.8em;
}

#ft-logo {
    height: 4em;
    width: 16.5em;
}

#ft-adr-tekst {
    // font-size: 1.5em;
}

#ft-sajt {
    // font-size: 1.5em;
    text-align: right;
    margin-right: 0;
}

.ft-cont-link {
    text-decoration: none;
    color: $bela;
}

#ft-soc {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    gap: 1em;
    padding-top: 0;
    margin-top: 0;
}

.ft-soc-item {
    text-decoration: none;
}

.ft-soc-btn {
    border-style: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 10px;
}

.ft-soc-item {
    text-decoration: none;
    list-style: none;
}

.ft-soc-img {
    height: 4em;
    width: 4em;
}

#ft-soc-tt {
    height: 3em;
}

#ft-soc-ig {
    height: 2.8em;
    width: 2.8em;
}


ul.ft-child li{
    list-style: none;
}


@media screen and (max-width: 1140px) {
    footer {
        font-size: 9px;
        padding: 2rem 5rem 1.5rem 5rem;
    }

    #ft-parent,
    .ft-adr-parent,
    .ft-adr-child,
    .ft-soc-btn {
        font-size: 7px;
    }
}

// @media screen and (max-width: 830px) {
//     footer {
//         font-size: 8px;
//         padding: 1.5rem 4rem 1rem 4rem;
//     }

//     #ft-parent,
//     .ft-adr-parent,
//     .ft-adr-child,
//     .ft-soc-btn {
//         font-size: 13px;
//     }
// }

@media screen and (max-width: 830px) {

    footer {
        font-size: 10px;
        padding: 0.8rem 1rem 0.5rem 1rem;
    }

    #ft-parent,
    .ft-adr-parent,
    .ft-adr-child,
    .ft-soc-btn {
        font-size: 10px;
    }

    #ft-parent {
        border-width: 0.5px;
        height: auto;
        flex-direction: column;
        gap: 1rem;
        p{
            text-align: center;
            font-size: 0.9rem;
        }
    }

    #ft-sajt{
        text-align: center;
        font-size: 0.9rem;
    }




    #ft-soc-joksa{
        flex-direction: column;

    }

    #ft-soc{
        justify-content: center;
        margin-top: 1rem;
    }
    #ft-logo{
        width: 50%;
        @media (min-width: 500px) {
            width: 30%;
            
        }
    }

    #ft-soc,
    #ft-adr-parent {
        gap: 0.3em;

    }

}
