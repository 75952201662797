
$bela: #F3F2FF;
$svetlo_plava: #24BDDE;
$svetlo_ljubicasta: #C539D9;
$tamno_ljubicasta: #7B2787;
$plava: #002440;

$font_header: 'Orbitron';
$font_body: 'Noto Sans';

@mixin backround_bc{

  //background: linear-gradient(113deg, #F3F2FF -45.89%, #7B2787 81.33%);
  background: linear-gradient(140deg, #F3F2FF -45.89%, #7B2787 81.33%); //Izmenio sam jer se ne vidi meni - Matija
}

@mixin backround_fh{
  background: linear-gradient(180deg, #002440 0%, #7B2787 100%);
}

@font-face {
  font-family: Orbitron;
  src: url(./Orbitron/Orbitron-VariableFont_wght.ttf);
}

@font-face {
  font-family: Orbitron;
  src: url(./Orbitron/Orbitron-VariableFont_wght.ttf);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-gap {
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;
}