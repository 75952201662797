@import '_variables.scss';

.App {

  text-align: center;

  display: flex;
  flex-direction: column;
  @include backround_fh;
}

.App > * {
  font-family: $font_body;
  // animation-name: scale;
  // animation-duration: 2s;

}

.App.App-bc {
  @include backround_bc;
}

.App-content{
  flex: 1 0 auto;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

}