@import '../../variables';

$visinaPortala: 10px;
$line-separator: 80px;

.agenda {

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: calc(100% - 400px);

    @media (max-width: 1300px) {
        width: 100%;
        
    }

    .agenda-naslov {
        font-family: $font_header;
        color: $bela;
        font-weight: 600;
        font-size: 1.8rem;
    }

    .agenda-timeline-wrapper {
        position: relative;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        width: 100%;
        height: 250vh;
    }

    .agenda-timeline {
        position: sticky;
        top: -1px;
        width: 90%;
        height: 100vh;
        scroll-behavior: auto;

        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: stretch;
        flex-direction: column;

        //background-color: rgba(0, 0, 0, 0.5);

        /*.agenda-final-wrap {
            height: 100%;
        }*/

        .agenda-ulazni-portal {
            width: 100%;
            min-height: 100px;
            background: url('assets/portalNaDole.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .aup-bc {
            background-image: url('assets/portalNaDoleBC.svg');
        }
        
        
        .agenda-izlazni-portal {
            width: 100%;
            min-height: 100px;
            background: url('assets/portalNaGore.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            cursor: pointer;
        }
        .aip-bc {
            background-image: url('assets/portalNaGoreBC.svg');
        }

        .agenda-astronaut-wrapper {
            position: absolute;
            left: 50%;
            //background: linear-gradient(yellow, red);
            width: 100px;
            height: 100px;
            //border-radius: 100%;
            transform: translate(-50%, -50%) scale(0);
            transition: top 0.3s ease-in-out, bottom 0.3s ease-in-out, transform 0.5s ease-in-out;


            .agenda-astronaut {
                background: url('./assets/Layer\ 2\ \(2\)\ 2.png');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                width: 100%;
                height: 100%;
                border-radius: 100%;
                cursor: pointer;
                filter: drop-shadow(0px 0px 3px black);

                transition-timing-function: cubic-bezier(.35, .65, .5, 1);
                transition-property: transform;
            }
        }

        .agenda-astronaut-wrapper.agenda-astronaut-popout {
            transform: translate(-50%, -50%) scale(1);
        }

        .agenda-linija {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            height: 100%;
            width: 2px;

            background: repeating-linear-gradient(to bottom, $bela 0 10px, transparent 10px 17px);
        }

        .agenda-blocks {
            position: relative;

            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-grow: 1;
            flex-direction: column;
            width: 100%;
            gap: 50px;
            padding: 20px 0;


            .agenda-block-wrapper {
                position: relative;
                width: 50%;

                display: flex;
                justify-content: flex-end;
                align-items: center;


                &:nth-of-type(even) {
                    align-self: flex-end;
                    justify-content: flex-start;

                    .agenda-loptica {
                        left: 0;
                        transform: translate(calc((-50%) - 1px), -50%);
                    }

                    .agenda-block {
                        margin-left: $line-separator;
                        margin-right: 0;

                        h1 {
                            color: $svetlo_plava;
                            text-align: right;
                        }
                    }
                }

                .agenda-loptica {
                    display: block;
                    position: absolute;
                    height: 20px;
                    width: 20px;
                    background-color: $bela;
                    border-radius: 100%;
                    cursor: pointer;

                    box-shadow: 0 0 3px 4px $bela;

                    top: 50%;
                    right: 0;
                    transform: translate(calc((50%) + 1px), -50%);

                }

                .agenda-block {
                    cursor: pointer;
                    text-align: justify;
                    max-width: 400px;
                    width: 300px;
                    margin-right: $line-separator;
                    margin-left: 0;


                    h1 {
                        font-family: $font_header;
                        font-size: 1.7rem;
                        color: $svetlo_plava;
                        text-align: left;
                    }

                    p {
                        font-family: $font_body;
                        color: $bela;
                    }
                }

                .agenda-block.agenda-block-bc {

                    h1,
                    p {
                        color: $bela;
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .agenda {
        .agenda-naslov {
            h1 {
                font-size: 3.5rem;
            }
        }


        .agenda-timeline {

            .agenda-astronaut-wrapper {
                width: 60px;
                height: 60px;
            }

            .agenda-ulazni-portal {
                min-height: 80px;
            }

            .agenda-izlazni-portal {
                min-height: 80px;
            }

            .agenda-blocks {
                .agenda-linija {
                    width: 1.5px;
                }

                .agenda-block-wrapper {

                    &:nth-of-type(even) {
                        .agenda-block {
                            margin-left: 40px;
                            margin-right: 0px;
                        }

                        .agenda-loptica {
                            transform: translate(-50%, -50%);
                        }
                    }

                    .agenda-block {
                        margin-right: 40px;

                        h1 {
                            color: $svetlo_plava;
                            font-size: 13pt;
                        }

                        p {
                            font-size: 7pt;
                        }
                    }

                    .agenda-loptica {
                        transform: translate(50%, -50%);
                        height: 15px;
                        width: 15px;
                    }

                    /*
                    .agenda-block.agenda-block-bc {
                        h1 {
                            color: $bela;
                        }

                        p {
                            color: $bela;
                        }
                    }*/
                }
            }
        }
    }
}