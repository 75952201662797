@import "../../variables";

.Faq {
    // @include backround_fh;

    overflow: hidden;
    margin-left: 50px;

    width: calc(100% - 500px);

    @media (max-width: 1300px){
        width: 100%;
        margin-left: 0;
    }
}

.Faq.Faq-bc {
    // @include backround_bc;
}

.flex-center-faq {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-faq {
    width: auto;
    min-height: 400px;
    margin: 2rem 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
}

.questions-holder-faq {
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.title-center-faq {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1 {
        font-family: $font_header;
        color: white;
        font-weight: 600;
        font-size: 2.4rem;
        width: 450px;
        margin-bottom: 40px;

        box-sizing: border-box;
        text-align: center;

    }
}

@media screen and (max-width: 800px) {
    .Faq {
        margin-left: 0px !important;
    }

    .title-center-faq {
        h1 {
            font-size: 1.8rem !important;
            width: 300px !important;
        }
    }
}

@media screen and (max-width: 450px) {
    .title-center-faq {
        h1 {
            font-size: 1.5rem !important;
            width: 300px !important;
        }
    }
}