@import '/src/variables';


.timKomponenta{
    width: calc(100% - 400px);

    img {
        object-fit: cover;
        object-position: top;
    }

    @media (max-width: 1300px) {
        width: 100%;
    }


}

@media (min-width: 850px){

    .timKomponenta{
        p{
            color: $bela;
            font-family: Orbitron;
            
        }
        
        .timovi{
            padding-top: 30px;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: row;
            position: relative;
            left: 184px;
        }
        
        .tekst{
            padding-left: 20px;
            width: 395px;
            text-align: justify;
            position: relative;
            right: 350px;

            
        }
        
        .naslov{
            position: relative;
            right: 190px;
            padding-top: 55px;
            font-size: 39px;
            font-weight: 500px;
        }
        
        .podnaslov1{
            font-weight: 500;
            font-size: 21px;
            padding-bottom: 12px;
        }
        
        .opis{
           font-size: 16px;
           font-weight: 500;
           padding-top: 7px;
        }
        
        .kordinator{
            padding-top: 37px;
            padding-left: 155px;    
        }
        .kordtekst{
            padding-top: 20px;
        
        }
        
        .korOkvirSlika{
            position: relative;
            z-index: 1;
            
            contain: style;
            
        }
        
        
        .strelica{
            position: relative;
            bottom: 25px;
            background-color: transparent;
            border: none;
            
        }
        
        .slikaKord{
            position: relative;
            right: 155px;
            z-index: 0;
            mask-image: url('./imgs/desktop/kor_okvir.svg');
            width: 155px;
            height: 147px;
           // height: 231px;
            
        }
        
        .okvirTim{
            position: relative;
            z-index: 2;
        }
        
        .timSlika{
            position: relative;
            right: 357px;
            bottom: 3px;
            mask-image: url('./imgs/desktop/timsvg.svg');
            z-index: 1;
            width:  350px;
            height: 237px;
        }
        
        
        button:hover{
            cursor: pointer;
           }
    }
    
       
}

@media (max-width: 849px) and (min-width: 500px) {

    .timKomponenta{
        .content{
            @include flex-center;
            flex-direction: column;
         }
     
         p{
             color: $bela;
             font-family: Orbitron;
         }
     
         .kord{
             position: relative;
            
             z-index: 2;
             padding-top: 30px;
         }
     
         .slikaKord{
            position: relative;
            object-fit: cover;
            z-index: 1;
            width:  132px;
            height: 125px;
            // right: 46px;
            
             right: 132px;
             z-index: -1;
             mask-image: url('./imgs/mobilna_verzija/kord_okvir_mobilno_svg.svg');
             
     
         }
     
         .strelica{
             background-color: transparent;
             border: none;
             position: relative;
             bottom: 40px;
             padding-left: 10px;
             padding-right: 10px;
         }
     
         .leva{
             padding-left: 132px;
         }
     
         .desna{
             position: relative;
             right: 135px;
         }
     
         .naslov{
             padding-top: 66px;
             font-size: 24px;
             font-weight: 400;
         }
     
         .imeKord{
             font-size: 16px;
             font-weight: 400;
             padding-top: 20px;
             padding-bottom: 8px;
         }
         
     
         .kordZa{
             font-size: 11px;
             font-weight: 400;
       
         }
     
         .okvirSlike{
             padding-top: 25px;
             padding-bottom: 15px;
             position: relative;
             z-index: 1;
            
             
         }
     
         .slikaTima{
             position: relative;
             bottom: 189px;
             mask-image: url('./imgs/mobilna_verzija/okvir_tima_svg.svg');
             z-index: 0;
             width: 245px;
             height: 162px;
             right: 5px;
             
             
         }
     
         .timZa{
             font-size: 16px;
             font-weight: 400;
             padding-bottom: 15px;
         }
     
         .opis{
             font-family: $font_body;
             font-size: 13px;
         }
         .opisContainer{
             width: 241px;
         }
     
         .tim{
             position: relative;
             bottom: 180px;
         }
    }

    
    
}


@media (max-width: 500px) and (min-width: 230px) {

    .timKomponenta{
        .content{
            @include flex-center;
            flex-direction: column;
         }
     
         p{
             color: $bela;
             font-family: Orbitron;
         }
     
         .kord{
             position: relative;
             z-index: 2;
             padding-top: 30px;
             // display: flex;
             // flex-direction: row;
         }
     
         .kordOkvir{
             // position: absolute;
             // right: 35px;
         }
     
         .slikaKord{
             position: relative;
             width: 132px;
             height: 125px;
             right: 132px;
             position: absolute;
             right: 46px;
             z-index: -1;
             mask-image: url('./imgs/mobilna_verzija/kord_okvir_mobilno_svg.svg');
     
         }
     
         .strelica{
             background-color: transparent;
             border: none;
             position: relative;
             bottom: 40px;
             padding-left: 10px;
             padding-right: 10px;
         }
     
         .leva{
             // padding-left: 132px;
         }
     
         .desna{
             // position: relative;
             // right: 135px;
         }
     
         .naslov{
             padding-top: 66px;
             font-size: 24px;
             font-weight: 400;
         }
     
         .imeKord{
             font-size: 16px;
             font-weight: 400;
             padding-top: 20px;
             padding-bottom: 8px;
         }
         
     
         .kordZa{
             font-size: 11px;
             font-weight: 400;
       
         }
     
         .okvirSlike{
             padding-top: 25px;
             padding-bottom: 15px;
             position: relative;
             z-index: 1;
             
         }
     
         .slikaTima{
             position: relative;
             bottom: 189px;
             mask-image: url('./imgs/mobilna_verzija/okvir_tima_svg.svg');
             z-index: 0;
             width: 245px;
             height: 162px;
             right: 5px;
         }
     
         .timZa{
             font-size: 16px;
             font-weight: 400;
             padding-bottom: 15px;
         }
     
         .opis{
             font-family: $font_body;
             font-size: 13px;
         }
         .opisContainer{
            //  width: 241px;
         }
     
         .tim{
             position: relative;
             width: 80%;
             bottom: 180px;
         }
    }

   
    
}

@media (max-width: 230px) {

    .timKomponenta{
        .content{
            @include flex-center;
            flex-direction: column;
         }
     
         p{
             color: $bela;
             font-family: Orbitron;
         }
     
         .kord{
             position: relative;
             z-index: 2;
             padding-top: 30px;
             // display: flex;
             // flex-direction: row;
         }
     
         .kordOkvir{
             // position: absolute;
             // right: 35px;
         }
     
         .slikaKord{
             // position: relative;
             // right: 132px;
             position: absolute;
             right: 27px;
             z-index: -1;
             mask-image: url('./imgs/mobilna_verzija/kord_okvir_mobilno_svg.svg');
     
         }
     
         .strelica{
             background-color: transparent;
             border: none;
             position: relative;
             bottom: 40px;
             padding-left: 1px;
             padding-right: 1px;
         }
     
         .leva{
             // padding-left: 132px;
         }
     
         .desna{
             // position: relative;
             // right: 135px;
         }
     
         .naslov{
             padding-top: 66px;
             font-size: 20px;
             font-weight: 400;
         }
     
         .imeKord{
             font-size: 16px;
             font-weight: 400;
             padding-top: 20px;
             padding-bottom: 8px;
         }
         
     
         .kordZa{
             font-size: 11px;
             font-weight: 400;
       
         }
     
         .okvirSlike{
             padding-top: 25px;
             padding-bottom: 15px;
             position: relative;
             z-index: 1;
             
         }
     
         .slikaTima{
             position: relative;
             bottom: 189px;
             mask-image: url('./imgs/mobilna_verzija/okvir_tima_svg.svg');
             z-index: 0;
             width: 245px;
             height: 162px;
             right: 5px;
         }
     
         .timZa{
             font-size: 16px;
             font-weight: 400;
             padding-bottom: 15px;
         }
     
         .opis{
             font-family: $font_body;
             font-size: 13px;
         }
         .opisContainer{
             width: 241px;
         }
     
         .tim{
             position: relative;
             bottom: 180px;
         }
         
    }

    
}


