

@import 'src/_variables';


h1{
    font-size: 3rem;
    font-weight: 400;
}

h2{
    font-size: 2rem;
    font-weight: 400;
}

.Partneri{
    // overflow: hidden;
    color: $bela;
    padding: 6rem 7rem 2rem 7rem;
    padding-top: 1rem;
}

.par-parent{
    
    font-family: $font_header;
    text-align: left;
    font-size: 4rem;
}

#naslov{
    padding-bottom: 1.5rem;
}
#pokrovitelj{
    margin-bottom: 2.5rem;
}
#pokrovitelj-naslov{
    font-family: $font_header;
    padding-bottom: 2rem;
}


:root{
    --gradient:linear-gradient(45deg,#24bdde,#7b2787);
}

.par-img-container{
    --border-width: 0.3rem;
    --border-radius:1.5rem;
    font: inherit;
    background: var(--gradient);
    border: 0;
    padding: 1rem 2rem;
    position: relative;

    border-radius: var(--border-radius);
    z-index: 1;

}

.par-img-container::before{
    content: '';
    display: block;
    height: calc(100% - calc(var(--border-width) * 2));
    width: calc(100% - calc(var(--border-width) * 2));
    background: #fff;
    position: absolute;
    top: var(--border-width);
    left: var(--border-width);
    border-radius: calc(var(--border-radius) - var(--border-width));
    z-index: -1;
}




#pokrovitelj-kutija{
    height:15rem;
    width: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > img{
        max-width: 23rem;
        max-height: 12rem;
    }

}




#par-btn-parent{ 
    @include flex-between;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 35rem;
    height: 3rem;
    margin-bottom: 1rem;

}



.par-btn-child{
    background-color: transparent;
    border:none;
    cursor: pointer;
}

.par-btn-img{
    width: 10rem;
    height: 3rem;
}

.par-img-slider{
    border-top: 4px solid transparent;
    border-image: linear-gradient(45deg,#24bdde,#7b2787) 1;
    padding-top: 1rem;
}


#par-img-parent{
    border-top: 4px solid transparent;
    border-image: linear-gradient(45deg,#24bdde,#7b2787) 1;
    padding-top: 1rem;
    
}

.par-img-child{
    max-width: 18rem; 
    height: 10rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    // animation:scroll 30s linear infinite;
}

.par-img{
    max-height:8rem;
    max-width: 16rem;
}


//mobilna verzija

.par-mob-parent{
    color: $bela;
}

.par-mob-grupa{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    text-align: center;
}

.mpar-img-child{
    width: 194px;
    height: 117px;
    @include flex-center;
}

.par-mob-parent{
    text-align: center;
}

.par-mob-btn{
    background-color: transparent;
    border: none;
}


.mpar-img{
    max-height: 100px;
    max-width: 150px;
}

#mob-pokrovitelj{
    display: grid;
    place-self: center;
    text-align: center;
    padding-top: 20px;
    font-size: 40px;

}

#mob-pokrovitelj-kutija{
    height: 144px;
    width: 229px;
    @include flex-center;
    place-self: center;
}

#par-mob-kontejner{
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 25px;
}

.par-mob-celina{
    padding-bottom: 15px;
}

.par-mob-naslov{
    font-family: $font_header;
    padding-bottom: 10px;
}

.slick-prev,
.slick-next {
  display: none !important;
}

#par-mob-med{
    padding-bottom: 20px;
}
