@import "../../variables";


header {
    margin: -3px calc(125vw/19.2);
    margin-bottom: 8px;
    margin-top: 5px;
    @include flex-between;
    // width: calc(100vw - calc(125vw/19.2)*2);
    // z-index: 5;

    .nav-hamburger{
        visibility: hidden;

        @media (max-width: 1300px) {
            visibility: visible;
        }
    }

    .nav-logo-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 25px;

        .nav-fh {
            background-image: url("./assets/FH\ Logo.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            width: 91px;
            height: 91px;
        }

        .nav-bc {
            background-image: url("./assets/Group\ 70.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            width: 227px;
            height: 91px;
        }
    }

    .nav-hamburger {
        position: relative;
        cursor: pointer;
        border: 4px solid $svetlo_plava;
        padding: 5px 6px;
        border-radius: 5px;
        z-index: 100;

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            border: 4px solid $svetlo_plava;
            height: 100%;
            width: 100%;
            filter: blur(5px);
        }

        .nav-hamburger-bar {
            display: block;
            position: relative;
            //width: 62px;
            width: 55px;

            height: 9px;
            border-radius: 9px;
            margin: 3.5px 0;
            transition: transform 0.3s ease-in-out, opacity 0.1s ease-in-out;
            background-color: $svetlo_plava;

            &::before {
                content: '';
                position: absolute;
                top: 0px;
                left: 0px;
                background-color: $svetlo_plava;
                height: 100%;
                width: 100%;
                filter: blur(3px);
            }

        }
    }



    .nav-hamburger.nav-hamburger-bc {
        border-color: $tamno_ljubicasta;

        &::before {
            border-color: $tamno_ljubicasta;
            filter: blur(5px);
        }

        .nav-hamburger-bar {
            background-color: $tamno_ljubicasta;

            &::before {
                background-color: $tamno_ljubicasta;
            }
        }
    }

    .nav-hamburger.active .nav-hamburger-bar {
        width: 55px;
    }

    .nav-hamburger.active .nav-hamburger-bar:nth-child(2) {
        opacity: 0;
        transform: scale(0);
    }

    .nav-hamburger.active .nav-hamburger-bar:nth-child(1) {
        transform: translateY(13px) rotate(45deg) scale(0.9);
    }

    .nav-hamburger.active .nav-hamburger-bar:nth-child(3) {
        transform: translateY(-13px) rotate(-45deg) scale(0.9);
    }
}

@media (max-width: 1920px) {
    header {
        .nav-logo-group {
            gap: 15px;

            .nav-fh {
                width: 63.7px;
                height: 63.7px;
            }

            .nav-bc {
                width: 159px;
                height: 73.71px;
            }
        }

        .nav-hamburger {
            scale: 1.1;
            display: flex;
            justify-content: space-between;
            align-content: center;
            flex-direction: column;
            gap: 3px;
            border-width: 3px;
            padding: 4.5px 5px;
            border-radius: 5px;
            width: 52.8px;

            &::before {
                filter: blur(7px);
                opacity: 0.7;
            }

            .nav-hamburger-bar {
                width: 38px;
                height: 6.48px;
                border-radius: 9px;
                margin: 0;

                &::before {
                    filter: blur(10px);
                    opacity: 0.5;
                    border-width: 3px;
                }
            }
        }

        .nav-hamburger.active .nav-hamburger-bar {
            width: 40px;
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(2) {
            opacity: 0;
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(1) {
            transform: translateY(9.5px) rotate(45deg) scale(0.9);
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(3) {
            transform: translateY(-9.5px) rotate(-45deg) scale(0.9);
        }

    }
}


@media (max-width: 700px) {
    header {
        .nav-logo-group {
            .nav-bc {
                background-image: url("./assets/Group\ 45.png");
                width: 48px;
                height: 73.71px;
            }
        }
    }
}

@media (max-width: 600px) {
    header {

        .nav-logo-group {
            justify-content: flex-start;
            gap: 10px;

            .nav-fh {
                width: 72.8px;
            }

            .nav-bc {
                background-image: url("./assets/BC\ Logo.svg");
                width: 45px;
                margin-left: 8px;
            }
        }
    }
}

//promenio jer jbg
@media (max-width: 400px) {
    header {
        .nav-logo-group {
            gap: 10px;

            .nav-fh {
                width: 44px;
                height: 44px;
            }

            .nav-bc {
                width: 25px;
                height: 44px;
                margin: 0;
            }

        }

        .nav-hamburger {
            display: block;
            scale: 1.05;
            border-width: 3px;
            padding: 2px 4px;
            border-radius: 5px;
            width: 42.79px;

            &::before {
                filter: blur(7px);
            }

            .nav-hamburger-bar {
                width: 30px;
                height: 5px;
                margin: 2px 0;

                &::before {
                    display: none;
                }
            }
        }

        .nav-hamburger.active .nav-hamburger-bar {
            width: 30px;
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(2) {
            opacity: 0;
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(1) {
            transform: translateY(7px) rotate(45deg) scale(0.9);
        }

        .nav-hamburger.active .nav-hamburger-bar:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg) scale(0.9);
        }

    }
}